<template>
  <div>
    <div class="subheader py-2 py-lg-6 subheader-transparent">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-1">
          <!--begin::Page Heading-->
          <div class="d-flex align-items-baseline flex-wrap mr-5">
            <!--begin::Page Title-->
            <h5 class="text-dark font-weight-bold my-1 mr-5">
              {{ this.$t("admin.form.create_company") }}
            </h5>
            <!--end::Page Title-->
          </div>
          <!--end::Page Heading-->
        </div>
        <b-button
          @click="goBack"
          size="sm"
          pill
          variant="secondary"
          class="font-weight-bolder mr-2 text-uppercase"
        >
          <div class="d-flex">
            <i class="fa fa-chevron-left" />
            <span style=" white-space: nowrap">
              {{ $t("generic.buttons.back") }}
            </span>
          </div>
        </b-button>
        <!--end::Info-->
      </div>
    </div>
    <basic-form
      @updatedForm="updateForm"
      :form="form"
      :fields="fields"
      :save="save"
      :error="error"
      :errors="errors"
      :isSubmitting="isSubmitting"
      v-if="loaded"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import {
  validZipCode,
  validText,
  validDigits,
  validateWhitespace,
  validateAddress,
  validateDomain,
  validMaxLength,
  validRFC5322
} from "@/utils/validators";
import {
  createStakeholder,
  updateStakeholderLogo,
  getUserAdminCompanies,
  getAdminStakeholder
} from "@/api/admin/stakeholders.api";
import {
  getCountries,
  getRegions,
  getRegionsByCountry,
  getSurveyFlows,
  getTimezones
} from "@/api/misc.api";
import { mapActions, mapGetters } from "vuex";
import { formatServicesById } from "@/utils/helpers-services";
import BasicForm from "@/components/form/BasicForm.component";
import { getStakeholderData } from "@/api/stakeholders.api";
import store from "@/store";
import {
  extractRootDomain,
  removeProfileData,
  getError
} from "@/utils/helpers";

export default {
  name: "CompanyCreate",
  components: {
    BasicForm
  },
  data() {
    return {
      othersSurveyflows: ["only_phishing", "only_abs", "only_phishing_and_abs"],
      companyChildren: {},
      loaded: false,
      errors: [],
      error: false,
      form: {},
      regionsByCountry: [],
      servicesBySurveyflow: [],
      countries: [],
      timezones: [],
      services: [],
      surveyflows: [],
      file: File,
      stakeholderRelationShip: localStorage.getItem("stakeholderRelationShip"),
      stakeholderId: localStorage.getItem("stakeholderId"),
      childrenCompanies: [],
      isSubmitting: false
    };
  },
  methods: {
    ...mapActions("Admin", ["loadAdminCompanies", "loadCompanySelected"]),
    callGetError(code, params) {
      return getError(code, params);
    },
    checkSurveyFlowId(surveyflow) {
      return _.includes(this.othersSurveyflows, surveyflow.id)
        ? -1
        : surveyflow.id;
    },
    updateForm(data) {
      this.form = data;
    },
    goBack() {
      const companiesAdmin = localStorage.getItem("adminProfiles").split(",");

      if (companiesAdmin.length > 1) {
        this.$router.push({ name: "AdminCompaniesManagement" });
      } else {
        this.$router.push({ name: "Company" });
      }
    },
    getServiceIds(data) {
      let result = {};
      let servicesBySurveyFlowIds = _.map(
        this.servicesBySurveyflow,
        service => service.id
      );

      let hasAbs =
        _.find(
          data.services,
          e => e.value == this.getConstant("SERVICETYPE_ABS")
        ) !== undefined;

      let servicesIds = [];
      if (data.services) {
        servicesIds = _.map(data.services, service => service.value);
      }
      if (!hasAbs) {
        result = {
          USER: servicesBySurveyFlowIds.concat(servicesIds),
          CONTROLLER: servicesBySurveyFlowIds.concat(servicesIds),
          ADMIN: servicesBySurveyFlowIds.concat(servicesIds)
        };
      } else {
        _.remove(servicesIds, el => el == this.getConstant("SERVICETYPE_ABS"));
        result = {
          USER: servicesBySurveyFlowIds.concat(servicesIds),
          CONTROLLER: servicesBySurveyFlowIds.concat(servicesIds),
          ADMIN: servicesBySurveyFlowIds.concat(servicesIds)
        };
        _.foreach(_.split(data.service_abs.id, "_"), element => {
          result[_.upperCase(element)].push(
            this.getConstant("SERVICETYPE_ABS")
          );
        });
      }
      return result;
    },
    save(data) {
      this.isSubmitting = true;
      this.error = false;
      this.errors = [];

      let formattedForm = {
        name: data.company,
        services: this.getServiceIds(data),
        fiscalCode: data.fiscalCode,
        address: data.address,
        domain: data.domain,
        zipCode: data.zipCode,
        email:
          "kymatio.controller+" + extractRootDomain(data.domain) + "@gmail.com",
        relationShip: data.relationShip?.id || "CUSTOMER",
        stakeholderCompanyId: data.stakeholderCompanyId?.stakeholderId || null,
        emailSupport: data.support || "soporte_usuarios@kymatio.com",
        emailNotification: data.support || "soporte_usuarios@kymatio.com",
        environment: {
          timezone: data.timezone,
          languages: {
            default: data.default_language,
            list: data.multi_language
          },
          passwords: {
            amount: data.amount,
            expiration: `${data.expiration}days`
          },
          notifications: {
            sendemail: data.sendemail ? "YES" : "NO",
            period: {
              user: data.user,
              manager: data.manager
            }
          }
        },
        journey: {
          surveyflowId: data.surveyflow
            ? this.checkSurveyFlowId(data.surveyflow)
            : []
        },
        profiles: {
          ADMIN: localStorage.getItem("stakeholderId").split(",")
        }
      };

      if (data.regionId) {
        formattedForm.regionId = data.regionId.regionId;
      }

      let formData = new FormData();

      if (data.logo) {
        const fileName = `logo.${data.logo.name.split(".").pop()}`;
        this.file = new File([data.logo], fileName, { type: data.logo.type });
        formData.append("logo", this.file);
      }

      createStakeholder("companies", formattedForm)
        .then(response => {
          const company = response?.data?.records;
          this.$root.$bvToast.toast(
            this.$t("admin.form.success.create_companyChildren_body", {
              name: company.name
            }),
            {
              title: this.$t(
                "admin.form.success.create_companyChildren_header"
              ),
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center"
            }
          );

          if (company) {
            if (data.logo) {
              updateStakeholderLogo(
                "companies",
                company.stakeholderId,
                formData
              ).catch(error => {
                this.errors.push({
                  name: error.response.data.records.userMessage,
                  code: error.response.data.records.applicationCode,
                  entity: "company"
                });
                this.$root.$bvToast.toast(
                  this.callGetError(
                    error.response.data.records.applicationCode
                  ),
                  {
                    title: this.callGetError("create_company", {
                      companyChildren: this.form.name
                    }),
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center"
                  }
                );
              });
            }
          }

          axios.all([getStakeholderData()]).then(
            axios.spread(stakeholderDataResp => {
              const data = stakeholderDataResp.data.records;
              localStorage.setItem(
                "adminProfiles",
                _.toString(data.profiles.ADMIN)
              );

              if (this.companySelected) {
                this.loadCompanySelected();
              }

              this.loadAdminCompanies();

              removeProfileData();
              localStorage.setItem("managedCompanyId", company.stakeholderId);
              localStorage.setItem("companySelected", JSON.stringify(company));
              store.dispatch("Admin/setCompany", company);
              this.$router.push({
                name: "Company"
              });
            })
          );
          this.isSubmitting = false;
        })
        .catch(error => {
          this.errors.push({
            name: this.getUserMessage(error.response.data.records.userMessage),
            code: error.response.data.records.applicationCode,
            entity: "company"
          });
          this.$root.$bvToast.toast(
            this.callGetError(error.response.data.records.applicationCode),
            {
              title: this.callGetError("create_company", {
                companyChildren: this.form.name
              }),
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center"
            }
          );
          this.isSubmitting = false;
        });
    },
    getUserMessage(userMessage) {
      switch (userMessage) {
        case "Services are mandatory":
          return "surveyflow";
        default:
          return userMessage;
      }
    },
    callGetRegionsByCountry(selectedOptions) {
      if (selectedOptions) {
        getRegionsByCountry(selectedOptions.countryId).then(
          regionsByCountryResponse => {
            if (this.form.regionId) {
              this.form.regionId = "";
            }
            this.regionsByCountry = regionsByCountryResponse.data.records;
            this.loaded = true;
          }
        );
      }
    },
    callGetServicesBySurveyflow(selectedOption) {
      let defaultServicesIds = [
        parseInt(this.getConstant("SERVICETYPE_PHISHING")),
        parseInt(this.getConstant("SERVICETYPE_ABS")),
        parseInt(this.getConstant("SERVICETYPE_FEDERATION")),
        parseInt(this.getConstant("SERVICETYPE_NEUROGAITAS")),
        parseInt(this.getConstant("SERVICETYPE_SCIM"))
      ];
      this.services = _.merge(
        this.services,
        formatServicesById(defaultServicesIds)
      );

      if (!_.includes(this.othersSurveyflows, selectedOption.id)) {
        let surveyflow = _.find(this.surveyflows, { id: selectedOption.id });
        this.servicesBySurveyflow = formatServicesById(
          surveyflow.serviceTypeId
        );
      } else {
        switch (selectedOption.id) {
          case "only_phishing":
            this.servicesBySurveyflow = [
              {
                id: parseInt(this.getConstant("SERVICETYPE_PHISHING")),
                name: this.$t("admin.form.surveyflow.surveyflows.phishing"),
                service: "phishing"
              }
            ];
            break;
          case "only_abs":
            this.servicesBySurveyflow = [
              {
                id: parseInt(this.getConstant("SERVICETYPE_ABS")),
                name: this.$t("admin.form.surveyflow.surveyflows.abs"),
                service: "abs"
              }
            ];
            break;
          case "only_phishing_and_abs":
            this.servicesBySurveyflow = [
              {
                id: parseInt(this.getConstant("SERVICETYPE_PHISHING")),
                name: this.$t("admin.form.surveyflow.surveyflows.phishing"),
                service: "phishing"
              },
              {
                id: parseInt(this.getConstant("SERVICETYPE_ABS")),
                name: this.$t("admin.form.surveyflow.surveyflows.abs"),
                service: "abs"
              }
            ];
            break;
        }
      }

      let isSocialEngineering = this.servicesBySurveyflow.some(
        element =>
          element.id ===
          parseInt(this.getConstant("SERVICETYPE_SOCIALENGINEERING"))
      );

      let isOnlyOption =
        selectedOption.id === "only_phishing" ||
        selectedOption.id === "only_abs" ||
        selectedOption.id === "only_phishing_and_abs";

      if (!isSocialEngineering) {
        this.services = this.services.filter(
          element => element.id != this.getConstant("SERVICETYPE_NEUROGAITAS")
        );
      }

      if (isOnlyOption) {
        this.services = this.services.filter(
          element => element.id != this.getConstant("SERVICETYPE_ABS")
        );
        this.services = this.services.filter(
          element => element.id != this.getConstant("SERVICETYPE_PHISHING")
        );
      }
    },
    addOtherSurveyFlows() {
      _.each(this.othersSurveyflows, surveyflow =>
        this.surveyflows.push({
          id: surveyflow,
          name: this.$t(`admin.form.surveyflow.other_surveyflows.${surveyflow}`)
        })
      );
    }
  },
  computed: {
    ...mapGetters("Admin", ["companySelected"]),
    ...mapGetters("Constants", ["getConstant"]),
    fields() {
      let fields = [
        {
          id: "company",
          required: true,
          type: "text",
          validation:
            validText(this.form.company) &&
            validMaxLength(this.form.company, 255)
        },
        {
          id: "domain",
          type: "text",
          required: true,
          validation: validateDomain(this.form.domain)
        },
        {
          id: "address",
          type: "text",
          required: true,
          validation:
            validateAddress(this.form.address) &&
            validMaxLength(this.form.address, 255)
        },
        {
          id: "zipCode",
          type: "text",
          required: true,
          validation: validZipCode(this.form.zipCode)
        },
        {
          id: "fiscalCode",
          type: "text",
          required: true,
          validation:
            validText(this.form.fiscalCode) &&
            validateWhitespace(this.form.fiscalCode) &&
            validMaxLength(this.form.fiscalCode, 45)
        },
        {
          id: "emailSupport",
          type: "text",
          required: false,
          validation: validRFC5322(this.form.emailSupport)
        },
        {
          id: "timezone",
          type: "select",
          required: true,
          options: this.timezones
        },
        {
          id: "multi_language",
          type: "multiselect",
          options: JSON.parse(localStorage.getItem("languages"))
        },
        {
          id: "default_language",
          type: "select",
          options: this.form.multi_language
        },
        {
          id: "country",
          type: "select",
          required: true,
          options: this.countries,
          label: "country",
          select: this.callGetRegionsByCountry
        },
        {
          id: "regionId",
          type: "select",
          required: true,
          label: "region",
          options: this.regionsByCountry
        },
        {
          id: "surveyflow",
          required: true,
          type: "select",
          label: "label",
          options: this.surveyflowOptions,
          servicesAsociated: this.servicesBySurveyflow,
          select: this.callGetServicesBySurveyflow
        },
        {
          id: "services",
          type: "multiselect",
          trackBy: "value",
          label: "text",
          options: this.form.surveyflow
            ? _.map(this.services, service => {
                return { text: service.name, value: service.id };
              })
            : []
        },
        {
          id: "service_abs",
          type: "select",
          info: true,
          required: true,
          label: "value",
          options: [
            {
              id: "admin_controller",
              value: this.$t(`admin.form.service_abs.admin_controller`)
            },
            { id: "admin", value: this.$t(`admin.form.service_abs.admin`) },
            {
              id: "admin_controller_user",
              value: this.$t(`admin.form.service_abs.admin_controller_user`)
            }
          ],
          condition:
            _.find(
              this.form.services,
              e => e.value == this.getConstant("SERVICETYPE_ABS")
            ) !== undefined
        },
        {
          id: "logo",
          type: "file",
          required: false
        },
        {
          id: "amount",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.amount) &&
            validateWhitespace(this.form.amount)
        },
        {
          id: "expiration",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.expiration) &&
            validateWhitespace(this.form.expiration)
        },
        {
          id: "user",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.user) && validateWhitespace(this.form.user)
        },
        {
          id: "manager",
          type: "text",
          required: true,
          validation:
            validDigits(this.form.manager) &&
            validateWhitespace(this.form.manager)
        },
        {
          id: "sendemail",
          type: "boolean",
          required: true,
          options: {
            checked: this.$t(`admin.form.yes`),
            unchecked: this.$t(`admin.form.no`)
          }
        }
      ];

      if (this.stakeholderRelationShip === "RESELLER") {
        fields.push({
          id: "relationShip",
          type: "select",
          required: true,
          label: "value",
          default: false,
          options: [
            { id: "RESELLER", value: this.$t(`admin.form.reseller_field`) },
            { id: "CUSTOMER", value: this.$t(`admin.form.customer_field`) }
          ]
        });

        fields.push({
          id: "stakeholderCompanyId",
          type: "select",
          label: "name",
          required: true,
          default: this.stakeholderCompanyId,
          options: this.childrenCompanies
        });
      }

      return _.filter(fields, field => field.condition !== false);
    },

    surveyflowOptions() {
      const surveyflows = [];
      _.each(this.surveyflows, surveyflow => {
        surveyflows.push({
          id: surveyflow.id,
          label: surveyflow.name
        });
      });

      return surveyflows;
    }
  },
  mounted() {
    axios
      .all([
        getCountries(),
        getRegions(),
        getSurveyFlows(),
        getTimezones(),
        getUserAdminCompanies(this.stakeholderId, { relationship: "RESELLER" }),
        getAdminStakeholder("companies", localStorage.getItem("companyId"))
      ])
      .then(
        axios.spread(
          (
            countriesResponse,
            regionsResponse,
            surveyFlowsResponse,
            timezonesResponse,
            companiesResponse,
            myCompanyResponse
          ) => {
            this.timezones = timezonesResponse.data.records;
            this.childrenCompanies = companiesResponse.data.records.map(
              function(o) {
                return Object.assign(
                  {
                    id: o.stakeholderId
                  },
                  _.omit(o, "id")
                );
              }
            );
            this.childrenCompanies.unshift(myCompanyResponse.data.records);

            this.companies = companiesResponse.data.records;
            const language = localStorage.getItem("language");
            this.countries = countriesResponse.data.records;
            this.regions = regionsResponse.data.records;
            this.surveyflows = surveyFlowsResponse.data.records;
            this.addOtherSurveyFlows();
            this.form = {
              surveyflowId: "",
              services: [],
              company: "",
              fiscalCode: "",
              address: "",
              logo: null,
              domain: "",
              country: this.countries[0],
              regionId: null,
              zipCode: "",
              email: null,
              emailSupport: "soporte_usuarios@kymatio.com",
              relationShip: {
                id: "CUSTOMER",
                value: this.$t(`admin.form.customer_field`)
              },
              timezone: "Europe/Madrid",
              default_language: language,
              multi_language: [language],
              amount: 10,
              expiration: 90,
              sendemail: true,
              user: 7,
              manager: 28,
              stakeholderCompanyId: myCompanyResponse.data.records,
              service_abs: {
                id: "admin_controller",
                value: this.$t(`admin.form.service_abs.admin_controller`)
              }
            };
            this.loaded = true;
            this.callGetRegionsByCountry({ countryId: 1 });
          }
        )
      );
  }
};
</script>
